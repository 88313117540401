<svelte:options tag="menu-subsistemas" />
<script>
    import { onMount } from "svelte"
    export let idarea = ''

    $: uId = Date.now()+'-'+ Math.ceil(Math.random()*100)

    $: api = GroupFetch.create({
            id:'menu-subsistemas',
            URI: 'https://data.sev.gob.mx/portal/homepage/api/subsistemas/',
            method: 'POST',
            key:'idArea'
        })
    $: groups = []

    $: init = async ()=>{
        api.
            add({idArea: idarea}).
            then(
                ({data})=>{
                    groups = grouping(data)
                }
            );
        }
    $: grouping = (data)=>{
        let resultGroups = []
        if(data.seAgrupa==0){
            resultGroups.push( 
                    {
                        etiqueta: '',
                        esVisible: false,
                        elementos: data.items
                    }
                )
            return resultGroups;
        }
        let groupsFound = [... new Set(data.items.map(e=>e.grupo))]
        groupsFound.forEach(
            (groupItem) => {
                resultGroups.push( 
                    {
                        etiqueta: groupItem,
                        esVisible: (data.seAgrupa == 1),
                        elementos: data.items.filter(e=>e.grupo==groupItem)
                    }
                )
            }
        );
        return resultGroups;
    }
    onMount( () => init() )
</script>

<div>
    {#each groups as singleGroup}
    <div class="titulo-seccion">{singleGroup.etiqueta}</div>
    <ul>
        {#each singleGroup.elementos as {etiqueta, prefijo, sufijo, descripcion, url, activo}}
            {#if activo == '1'}
                <li>
                    <a href="{url}" target="_blank" title="Ir ahora {prefijo} {descripcion} {sufijo}" alt="Ir ahora a {prefijo} {descripcion} {sufijo}">
                        {etiqueta}
                    </a>
                </li>
            {/if}
        {/each}
    </ul>
    {/each}
</div>

<style scoped>
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0 0 30px 0;
    }
    ul li {
        margin: 0 0 5px 5px;
    }
    ul li a{
        color: #55565A;
        display: block;
        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
    }
    ul li a:hover {
        color: #8F8F8F;
    }
    ul li a:hover::after{
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHRpdGxlPmlvbmljb25zLXY1LWs8L3RpdGxlPjxwYXRoIGQ9Ik0zODQsMjI0VjQwOGE0MCw0MCwwLDAsMS00MCw0MEgxMDRhNDAsNDAsMCwwLDEtNDAtNDBWMTY4YTQwLDQwLDAsMCwxLDQwLTQwSDI3MS40OCIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjMycHgiLz48cG9seWxpbmUgcG9pbnRzPSIzMzYgNjQgNDQ4IDY0IDQ0OCAxNzYiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiMwMDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDozMnB4Ii8+PGxpbmUgeDE9IjIyNCIgeTE9IjI4OCIgeDI9IjQ0MCIgeTI9IjcyIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMDAwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MzJweCIvPjwvc3ZnPg==');
        background-size: 12px 12px;
        display: inline-block;
        width: 11px; 
        height: 11px;
        margin-left: 2px;
        content:"";
    }

    .titulo-seccion {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        color: #7a6f46;
        margin: 15px 0 10px 0;
    }
</style>